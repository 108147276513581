@import "~react-image-gallery/styles/css/image-gallery.css";

* {
    --primaryColor: #F00CB3;
    --secondaryColor: white;
    --thirdColor: #8BCCC8;
    --forthColor: #f569cf;
    --textColor: #5E5953;
    font-family: 'Raleway', sans-serif;
}

@media only screen and (max-device-width: 480px){
    .navigation {
        background-color: var(--primaryColor);
    }
    
    .navigation-bar {
        text-align: center;
        background-color: var(--primaryColor);
        color: var(--secondaryColor);
        position: relative;
        top: 20px;
        bottom: 20px;
    }
    
    .nav-item {
        display: inline;
        margin: auto;
        color: var(--secondaryColor);
        text-decoration: none;
    }
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: var(--primaryColor);
}


a {
    color: inherit;
    /* blue colors for links too */
    text-decoration: inherit;
    /* no underline */
}

a:hover {
    color: var(--primaryColor);
}

button {
    background-color: var(--primaryColor);
    color: var(--secondaryColor);
    padding: 15px;
    border-radius: 10px;
    border-color: var(--forthColor);
    margin-top: 35px;
    margin-bottom: 15px;
}

button:hover {
    background-color: var(--forthColor);
}

p {
    color: var(--textColor);
    line-height: 18pt;
}

.logo {
    width: 200px;
    margin-right: 3%;
}

.learnMore:hover {
    color: var(--primaryColor);
}

.navigation{
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    padding-left: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin:auto;
}

.navigation-bar {
    text-align: center;
    background-color: var(--primaryColor);
    color: var(--secondaryColor);
    padding-left: 50px;
    position: relative;
    bottom: 20px;
}

.nav-item {
    display: inline;
    margin-left: 50px;
    color: var(--secondaryColor);
    text-decoration: none;
}

.selected {
    display: inline;
    margin-left: 50px;
    color: var(--thirdColor);
    text-decoration: none;
}

.selected:hover {
    color: var(--thirdColor);
}

.nav-item:hover {
    color: var(--thirdColor);
}

.main {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    width: 90%;
    background-color: var(--secondaryColor);
    color: var(--thirdColor);
    margin: auto;
}

.footerDiv {
    display: flex;
    padding-top: 50px;
    padding-left: 70px;
    padding-bottom: 30px;
}

.footerDiv>*, .footerDiv>*>p, .footerDiv>*>*>p {
    color: var(--secondaryColor);
}

.contact {
    display: block;
    position: absolute;
    right: 20%;
}

.identity {
    text-align: center;
    padding-bottom: 30px;
    font-size: small;
}

.socials {
    height: 35px;
    padding-right: 10px;
}

.socialItem {
    display: flex;
    align-items: center;
}

.programItem {
    border: var(--thirdColor);
    border-style: solid;
    padding: 30px;
    display: flex;
    position: relative;
    margin-bottom: 30px;
}

.programItem>div {
    position:relative;
    top: -10px;
    padding-left: 30px;
    padding-right: 30px;
}

.programsGrid {
    display: flex;
    flex-wrap: wrap;
    padding-left: 30px;
}

.programItemGrid {
    width: 27%;
    padding: 10px;
    padding-right: 20px;
    position: relative;
    margin-bottom: 30px;
    margin-left: 10px;
}

.programItem>div>button {
    position: absolute;
    right: 200px;
}

.image-gallery{
    width: 50%;
    display: block;
}

.programDes {
    width: 45%;
    display: block;
    padding-right: 50px;
}

.programInfo {
    display: flex;
    flex-wrap: wrap;
}

.desPhotoGrid {
    width: 100%;
    display: block;
    margin-bottom: 5px;
    border: solid;
  margin-left: auto;
  margin-right: auto;
}
.desPhotoGrid:hover {
    transform: scale(1.03);
}

.desPhoto {
    width: 35%;
    height: 100%;
}

.banner {
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.newsList {
    display: flex;
    flex-wrap: wrap;
}

.newsItem {
    width: 28%;
    margin-left: 40px;
    margin-bottom: 50px;
}

.newsPhoto {
    width: 300px;
    transition: 0.5s all ease-in-out;
}

.newsPhoto:hover {
    transform: scale(1.07);
}

.worksheets{
    border:#F00CB3;
    border-style: solid;
    padding: 10pt;
    display: flex;
    flex-wrap: wrap;
}

.toolList {
    display: flex;
    flex-wrap: wrap;
}

.toolItem {
    border: #F00CB3;
    padding: 10pt;
    border-style: solid;
    width: 20%;
    margin-left: 25px;
    margin-bottom: 50px;
}

.toolLogo {
    width: 170px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    transition: 0.5s all ease-in-out;
}

.toolLogo:hover {
    transform: scale(1.07);
}

.awardsList {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: space-around;
    margin: auto;
    width: 90%;
}

.awardItem {
    width: 300px;
    align-items: center;
    text-align: center;
    padding-right: 30px;
    padding-bottom: 15px;
}

.awardLogo {
    width: 250px;
    transition: 0.5s all ease-in-out;
}

.awardLogo:hover {
    transform: scale(1.09);
}

.partnersList {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: space-around;
    margin: auto;
    width: 90%;
    padding-top: 30px;
}

.partnerItem {
    width: 200px;
    align-items: center;
    text-align: center;
    padding-right: 30px;
    padding-bottom: 15px;
}

.partnerLogo {
    width: 150px;
    transition: 0.5s all ease-in-out;
}

.partnerLogo:hover {
    transform: scale(1.09);
}

.team {
    display: flex;
}

.teamMember {
    display: flex;
    padding-right: 150px;
    width: 500px;
}

.teamMember>h3:hover {
    color: var(--primaryColor);
}

.teamImg {
    padding-right: 30px;
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.box {
    width: 40%;
    margin: 0 auto;
    background: rgba(255,255,255,0.2);
    padding: 35px;
    border: 2px solid #fff;
    border-radius: 20px/50px;
    background-clip: padding-box;
    text-align: center;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
  }

  .overlay:target {
    visibility: visible;
    opacity: 1;
  }
  
  .popup {
    margin: 70px auto;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 30%;
    position: relative;
    transition: all 5s ease-in-out;
  }
  
  .popup h2 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
  }
  .popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
  }
  .popup .close:hover {
    color: #06D85F;
  }
  .popup .content {
    max-height: 30%;
    overflow: auto;
  }
  
  @media screen and (max-width: 700px){
    .box{
      width: 70%;
    }
    .popup{
      width: 70%;
    }
  }

.impactList {
    display: flex;
    justify-content: space-around;
    margin: auto;
}

.impactItem {
    width: 200px;
    text-align: left;
    padding-right: 25px;
    padding-left: 25px;
    border-left: var(--primaryColor);
    border-left-style: solid;
}

.involvedItem {
    padding-bottom: 15px;
    padding-top: 15px;
    padding-right: 50px;
    display: flex;
}

.getInvolved {
    padding-left: 30px;
}

.image {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    width: 500px;
    height: auto;
    padding-left: 150px;
}

.sideBySide {
    display: flex;
}

.sideBySide>div {
    padding-left: 30px;
}

.fade-in {
    animation: fadeIn ease 10s;
    -webkit-animation: fadeIn ease 10s;
    -moz-animation: fadeIn ease 10s;
    -o-animation: fadeIn ease 10s;
    -ms-animation: fadeIn ease 10s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fade-out {
    animation: fadeOut ease 8s;
    -webkit-animation: fadeOut ease 8s;
    -moz-animation: fadeOut ease 8s;
    -o-animation: fadeOut ease 8s;
    -ms-animation: fadeOut ease 8s;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-moz-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-o-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-ms-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}